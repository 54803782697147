/**
 * This component is only used to generate blog pages.
 * It should not be used inside other page templates
 * since it contains a graphQL call.
 */

import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import SectionHeader from '../section-header'
import ForwardArrow from '../Icons/ArrowForward'
import ColorSwitch from '../waypoint-color-switch'

import Layout from '../layout'
import BlogPage from '../../templates/blog-page'

require('../../scss/perspectives.scss')

const WEBSITE_URL = process.env.GATSBY_WEBSITE_URL

const BlogPost = ({ data: { current, next } }) => {
  if (!current?.data?.author.document) {
    return null
  }
  const slug = current.data.slug
  const { data } = current
  const title = data.title
  const keywords = data.keywords

  let summary
  for (let i = 0; i < data.body.length; i++) {
    if (data.body[i].slice_type === 'rich_text') {
      summary = data.body[i].primary.rich_text.text.substring(0, 140)
      break
    }
  }
  let helmet_image = `${WEBSITE_URL}/images/versett.png`
  for (let i = 0; i < data.body.length; i++) {
    if (data.body[i].slice_type === 'image') {
      helmet_image = data.body[i].primary.image.url
      break
    }
  }

  return (
    <Layout startingClass="color-switch--white">
      <Helmet
        title={title}
        meta={[
          {
            name: 'description',
            content: summary,
          },
          {
            name: 'keywords',
            content: `versett,writing${keywords ? `,${keywords}` : ''}`,
          },
          {
            property: 'og:title',
            content: title,
          },
          {
            property: 'og:description',
            content: summary,
          },
          {
            property: 'og:image',
            content: helmet_image,
          },
          {
            property: 'og:url',
            content: `${WEBSITE_URL}/writing/${slug}`,
          },
          { name: 'twitter:card', content: 'summary_large_image' },
          { property: 'og:site_name', content: 'Versett' },
          { name: 'twitter:image:alt', content: 'Versett' },
        ]}
      >
        <html lang="en" className="perspectives" />
      </Helmet>
      <BlogPage post={current} />
      <ColorSwitch transitionColor="black" previousColor="white" />
      {next && (
        <section class="s-container">
          <div className="perspectives__read-next s-section optimal-width optimal-width--center">
            <a href={`/writing/${next.data.slug}`}>
              <SectionHeader heading="Read this next" lede={next.data.title}>
                <div
                  dangerouslySetInnerHTML={{ __html: next.data.summary.html }}
                />
                <ForwardArrow />
              </SectionHeader>
            </a>
          </div>
        </section>
      )}
    </Layout>
  )
}

export const pageQuery = graphql`
  query PostBySlug($slug: String, $next: String, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    current: prismicBlogPost(
      data: { slug: { eq: $slug } }
      lang: { eq: $language }
    ) {
      first_publication_date
      data {
        title
        category
        author {
          document {
            ... on PrismicBlogAuthor {
              data {
                name
                title
                bio
                profile_picture {
                  url
                }
              }
            }
          }
        }
        body {
          ... on PrismicBlogPostDataBodyQuote {
            slice_type
            primary {
              text
            }
          }
          ... on PrismicBlogPostDataBodyRichText {
            slice_type
            primary {
              rich_text {
                text
                html
              }
            }
          }
          ... on PrismicBlogPostDataBodyImageGrid {
            slice_type
            primary {
              grid_type
            }
            items {
              image {
                url
                alt
              }
            }
          }
          ... on PrismicBlogPostDataBodyImage {
            slice_type
            primary {
              image {
                url
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicBlogPostDataBodyImage1 {
            slice_type
            primary {
              image {
                url
                dimensions {
                  width
                  height
                }
              }
            }
          }
          __typename
        }
      }
    }
    next: prismicBlogPost(
      data: { slug: { eq: $next } }
      lang: { eq: $language }
    ) {
      data {
        title
        summary {
          html
        }
        slug
      }
    }
  }
`

export default BlogPost
