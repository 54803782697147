import React, { useEffect, useState } from 'react'

const ScrollIndicator = () => {
  const [scrolled, setScrolled] = useState(100)

  useEffect(() => {
    // When the user scrolls the page, execute myFunction
    document.addEventListener('scroll', calculateScrollPercentage)
    document.addEventListener('load', calculateScrollPercentage)

    function calculateScrollPercentage() {
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop
      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight
      let scrollPercent = 100 // Default value: fill width

      // Only if document is scrollable
      if (height > 0) {
        scrollPercent = (winScroll / height) * 100
      }
      setScrolled(scrollPercent)
    }
    return () => {
      document.removeEventListener('scroll', calculateScrollPercentage)
      document.removeEventListener('load', calculateScrollPercentage)
    }
  }, [])

  return (
    <div
      className="c-scroll-indicator"
      style={{
        backgroundImage: `linear-gradient(to right, black ${scrolled}%, white 0)`,
      }}
    />
  )
}

export default ScrollIndicator
