import React from 'react'
import PageHeader from '../../components/page-header'
import ScrollIndicator from '../../components/scroll-indicator'
import Block from '../../components/waypoint-block'
import ImageGrid from '../../components/image-grid'

const WEBSITE_URL = process.env.GATSBY_WEBSITE_URL

const BlogPage = ({ post }) => {
  const author = post.data.author.document.data
  let summary
  for (let i = 0; i < post.data.body.length; i++) {
    if (post.data.body[i].slice_type === 'rich_text') {
      summary = post.data.body[i].primary.rich_text.text.substring(0, 140)
      break
    }
  }
  const content = post.data.body.map((slice, index) => {
    switch (slice.slice_type) {
      case 'rich_text':
        return (
          <div
            className="perspectives__text optimal-width optimal-width--center"
            key={index}
          >
            <div
              dangerouslySetInnerHTML={{ __html: slice.primary.rich_text.html }}
            />
          </div>
        )
      case 'image_1':
        return (
          <div
            className="perspectives__image optimal-width optimal-width--smaller optimal-width--center"
            key={index}
          >
            <img src={slice.primary.image.url} alt={slice.primary.image.alt} />
          </div>
        )
      case 'image':
        return (
          <div
            className="perspectives__authorSignature optimal-width optimal-width--smaller optimal-width--center"
            key={index}
          >
            <img src={slice.primary.image.url} alt={slice.primary.image.alt} />
          </div>
        )
      case 'image_grid':
        return (
          <ImageGrid
            key={index}
            direction={slice.primary.grid_type}
            images={slice.items}
            tall
          />
        )
      case 'quote':
      case 'quote_with_person':
        return (
          <blockquote
            key={index}
            className="perspectives__quote optimal-width optimal-width--smaller optimal-width--center"
          >
            <span>{slice.primary.text}</span>
            {slice.primary.person && (
              <cite className="person">&ndash; {slice.primary.person}</cite>
            )}
          </blockquote>
        )
      default:
        return null
    }
  })

  return (
    <React.Fragment>
      <ScrollIndicator />
      <section className="s-container">
        <div className="optimal-width optimal-width--center perspectives__heading">
          <PageHeader
            lede={post.data.title}
            heading={post.data.category}
            smallMargin={true}
          />
        </div>
      </section>
      <Block>
        <section className="s-container">{content}</section>
        <section class="s-container">
          <div className="post__share optimal-width optimal-width--center">
            <div className="content">
              <div className="title">Share</div>
              <div className="social">
                <div>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`http://www.facebook.com/sharer.php?u=${WEBSITE_URL +
                      '/perspectives/' +
                      post.data.slug}`}
                  >
                    Facebook
                  </a>
                </div>
                <div>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://twitter.com/intent/tweet?text=${
                      post.title
                    } ${WEBSITE_URL +
                      '/perspectives/' +
                      post.data.slug}&hashtags=versett`}
                  >
                    Twitter
                  </a>
                </div>
                <div>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://www.linkedin.com/shareArticle?mini=true&url=${
                      post.title
                    } ${WEBSITE_URL +
                      '/perspectives/' +
                      post.data.slug}&title=${
                      post.title
                    }&summary=${summary}&source=website`}
                  >
                    LinkedIn
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="s-container">
          <div className="optimal-width optimal-width--center post__author">
            <div className="content">
              <div className="profile-picture">
                <div className="img-container">
                  <img src={author.profile_picture.url} alt={author.name} />
                </div>
              </div>
              <div className="info">
                <p className="author">
                  {author.name}, {author.title}
                </p>
                <p className="bio">{author.bio}</p>
              </div>
            </div>
          </div>
        </section>
      </Block>
    </React.Fragment>
  )
}

export default BlogPage
